<template>
	<div class="main-content">
		<div class="header bg-white border-bottom">
			<div class="container-fluid">
				<div class="header-body border-0">
					<div class="row align-items-end">
						<div class="col">
							<h6 class="header-pretitle">Overview</h6>
							<h1 class="header-title">Notify Users</h1>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container-fluid">
			<b-breadcrumb :items="items"></b-breadcrumb>
		</div>
		<div class="container-fluid">
			<template>
				<div class="row">
					<div class="col-12 col-xl-6 align-self-baseline">
						<b-card class="text-start py-2 px-3" no-body>
							<div
								class="input-group input-group-flush d-flex flex-row-reverse"
								>
								<input
									v-model="notification.title"
									class="form-control"
									type="text"
									placeholder="Enter notification title"
									ref="titleRef"
									/>
							</div>
						</b-card>
						<div class="card selected-users">
							<div class="card-header">
								<div
									class="input-group input-group-flush d-flex flex-row-reverse"
									>
									<div
										class="input-group input-group-flush d-flex flex-row-reverse"
										>
										<input
											v-if="!selectedUsers.length"
											v-model.trim="search"
											class="form-control list-search"
											type="search"
											placeholder="Add users"
											@keyup.enter.prevent="fetchUsers(true)"
											/>
									</div>
								</div>
								<p
									v-if="notificationType === 'regular'"
									class="input-group-text border-0 rounded-full px-3 py-2"
									style="
                    background-color: #f4f2f2;
                    color: black;
                    font-weight: bold;
                  "
									>
									{{ selectedUsers.length }} user selected
								</p>
								<p
									v-if="notificationType === 'all'"
									class="input-group-text border-0 rounded-full px-3 py-2"
									style="
                    background-color: #f4f2f2;
                    color: black;
                    font-weight: bold;
                  "
									>
									All <span class="underline mx-2" v-if='filter.corporateId'>{{ companyName(filter.corporateId) }} ({{ selectedUsers.length  }})</span>   users selected
								</p>
							</div>
							<template
								v-if="
									notificationType === 'regular' && selectedUsers.length > 0
								"
								>
								<div class="container-fluid py-2" >
									<div
										class="badge bg-light mr-2 mb-2 py-2"
										v-for="user in selectedUsers"
										:key="user.id"
										>
										<span
											class="rounded-circle p-1 mr-2"
											style="
                        color: #0dac5c;
                        background-color: #ceeede;
                        font-weight: bold;
                      "
											>{{ user?.fname?.slice?.(0, 1).toUpperCase() }}
											{{ user?.lname?.slice?.(0, 1).toUpperCase() }}</span
											>

										<span class="font-weight-bold">
											{{ user.fname }}
											{{ user.lname }}
										</span>

										<span
											@click="removeSelectedUser(user)"
											class="font-weight-bold"
											style="cursor: pointer"
											><i class="fe fe-x"></i
												></span>
									</div>
								</div>
							</template>

							<div>
								<form>
									<div class="p-3">
										<TextEditor @update:modelValue="updatePostBody" />
									</div>
									<div
										class="d-flex justify-content-between align-items-center mt-5 px-3 pb-3"
										>
                    <div class="flex flex-col gap-[12px]">
                      <div class="d-flex align-items-center gap-[10px]">
                        <VueToggles width="50" checkedBg="#2196f3" :value="notification.isSms" 
                          @click="notification.isSms = !notification.isSms" 
                        />
                        <p class="font-weight-bold m-0">
                          Enable sms notification
                        </p>
                      </div>
                      <div class="d-flex align-items-center gap-[10px]">
                        <VueToggles width="50" checkedBg="#2196f3" :value="notification.isEmail" 
                          @click="notification.isEmail = !notification.isEmail" 
                        />
                        <p class="font-weight-bold m-0">
                          Enable Email notification
                        </p>
                      </div>
                    </div>

										<div>
											<button
												@click.prevent="notifyUsers()"
												class="btn btn-primary border border-1"
												:disabled="
													processing ||
														processingAll ||
														buttonsDisabled ||
														disabledButton
												"
												>
												{{ processingAll ? 'Processing...' : 'Notify users' }}
											</button>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>

					<div class="col-12 col-xl-6 align-self-stretch">
						<b-card class="text-start font-weight-bold p-3" no-body>
							<div class="d-flex align-items-center">
								<div class="px-0 flex-grow-1">
									<div
                   v-if="sortDirection !== 'Company'"
										class="input-group input-group-flush d-flex flex-row-reverse"
										>
										<input

											v-model.trim="search"
											class="form-control list-search"
											type="search"
											placeholder="Search users"
											@keyup.enter.prevent="fetchUsers(true)"
											/>
										<span class="input-group-text border-0">
											<i
												class="fe fe-search pointer"
												@click.prevent="fetchUsers(true)"
												></i>
										</span>
									</div>
								</div>

								<div class="d-flex justify-content-end">
									<div class="d-flex align-items-center">
										<p class="pb-0 mb-0" style="font-size: 12px">Filter by:</p>
										<b-form-select
											id="initial-sort-select"
											v-model="sortDirection"
											:options="['All', 'Company']"
											size="sm"
											style="width: 60%"
											class="ml-3"
											></b-form-select>
									</div>
								</div>
							</div>
						</b-card>

						<b-card align="center">
							<template #header>
								<div class="d-flex align-items-center">
									<div class="flex-grow-1 mt-3">
										<div
											class="d-flex align-items-center"
											v-if="activeListTable === 'Company'"
											>
											<p class="mr-3" style="font-size: 13px; color: #b9bcc8">
												Select company:
											</p>
											<v-select
												v-model="filter.corporateId"
												class="form-group"
												:options="companies"
												label="corporate_name"
												:reduce="(company) => company.id"
												placeholder="Select a company"
												style="width: 60%"
												required
												>
											</v-select>
										</div>
									</div>

									<div class="d-flex align-items-center" v-if="sortDirection !== 'Company'">
										<p
											class="pb-0 mb-0"
											style="font-size: 13px; color: #4848ed"
											>
											select all users
										</p>
										<input
											@change="handleAllUsersSelection($event)"
											v-model="notification.notifyAll"
											type="checkbox"
											id="notify-all"
											class="ml-2"
											/>
									</div>
								</div>
							</template>

							<b-card-body>
								<notify-table>
									<template slot="table1"	v-if="sortDirection == 'All' || sortDirection == 'Company' ">
										<b-table
											v-if="showTable"
											selectable
											responsive
											:items="users"
											:fields="fields"
											:current-page="currentPage"
											:busy="fetchingData"
											:empty-text="'No users available.'"
											small
											>
											<template #table-busy>
												<div class="text-center text-secondary my-2">
													<div class="spinner-border" role="status"></div>
												</div>
											</template>

											<template #empty="scope">
												<h4 class="text-center">{{ scope.emptyText }}</h4>
											</template>

											<template #cell(user)="data">
												<div class="d-flex align-items-center">
													<div class="mr-2">
														<user-avatar
															:user="data.item"
															size="sm"
															></user-avatar>
													</div>
													<div class="d-flex flex-column align-items-start">
														<p
															class="pb-0 mb-0"
															style="font-weight: bold; font-size: 13px"
															>
															{{ data.item.email }}
														</p>
														<p
															class="pt-0 mt-0"
															style="font-weight: lighter; font-size: 13px"
															>
															{{ data.item.phone }} 
														</p>
													</div>
												</div>
											</template>

											<template #cell(checkAll)="data">
												<input
													:checked="selectedUsers.length &&
														selectedUsers.find(
															(user) => user.id === data.item.id
														) || notification.notifyAll
													"
													@input="selectUser(data.item)"
                          :disabled="sortDirection === 'Company'"
													type="checkbox"
													id="notify-user"
													/>
											</template>
										</b-table>
										<div class="flex text-lg text-center w-full p-4" v-else>
											Select a company
										</div>
									</template>

									<!-- <template slot="table2">
										<b-table
											v-if="sortDirection == 'Route'"
											selectable
											responsive
											:items="routes"
											:fields="fields"
											:current-page="currentPage"
											:busy="fetchingData"
											small
										>
											<template #table-busy>
												<div class="text-center text-secondary my-2">
													<div class="spinner-border" role="status"></div>
												</div>
											</template>

											<template #empty>
												<h4>There are no users</h4>
											</template>

											<template #cell(user)="data">
												<div class="d-flex align-items-center">
													<div class="mr-2">
														<div
															style="
                                color: #0dac5c;
                                background-color: #ceeede;
                                font-weight: bold;
                              "
															class="rounded-circle p-3"
														>
															{{
																data?.item?.route_code
																	? data?.item?.route_code?.slice?.(0, 2)
																	: 'N/A'
															}}
														</div>
													</div>
													<div class="d-flex flex-column align-items-start">
														<p
															class="pb-0 mb-0"
															style="font-weight: bold; font-size: 13px"
														>
															{{ data.item.route_code }}
														</p>
														<p
															class="pt-0 mt-0"
															style="font-weight: lighter; font-size: 13px"
														>
															James Ike, Ruby Brown, Sterling hard +1 other
														</p>
													</div>
												</div>
											</template>

											<template #cell(checkAll)="data">
												<input
													:checked="
														selectedUsers.find(
															(user) => user.id === data.item.id
														) || notification.notifyAll
													"
													@input="selectUser(data.item)"
													type="checkbox"
													id="notify-user"
												/>
											</template>
										</b-table>
									</template> -->
								</notify-table>

								<b-card-footer
									v-if="totalRecords && sortDirection !== 'Company'"
									class="d-flex justify-content-center align-items-center"
									>
									<b-pagination
										v-if="users.length && !filter.corporateId"
										v-model="currentPage"
										:total-rows="totalRecords"
										:per-page="perPage"
										></b-pagination>
								</b-card-footer>
							</b-card-body>
						</b-card>
					</div>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
import Swal from 'sweetalert2'
import { extractErrorMessage } from '@/utils/helpers'
import RouteResource from '@/api/route'
import NotifyTable from '@/views/Users/components/NotifyTable.vue'
import UserAvatar from '@/components/core/UserAvatar.vue'
import TextEditor from '../components/TextEditor.vue'
import VueToggles from 'vue-toggles'

export default {
  props: {
    userId: {
      type: [Number, String]
    }
  },
  components: {
    UserAvatar,
    NotifyTable,
    TextEditor,
    VueToggles
  },
  data () {
    return {
      notificationType: 'regular',
      sortDirection: 'All',
      activeListTable: 'All',
      routesFilter: '',
      companiesList: [],
      companies: [],
      items: [
        {
          text: 'Users',
          to: { name: 'UsersList' },
          active: false,
          exact: true
        },
        {
          text: 'Notify users',
          to: { name: 'NotifyUser' },
          active: true,
          exact: true
        }
      ],
      fields: [
        {
          key: 'user',
          label: 'User',
          thStyle: {
            display: 'none'
          }
        },
        {
          key: 'checkAll',
          label: 'Selected',
          thStyle: {
            display: 'none'
          }
        }
        // {
        //   key: 'checkAllUsers',
        //   label: 'a',
        // },
      ],
      cityFilter: {
        id: null,
        name: 'All Cities',
        city_id: null
      },
      perPage: 6,
      currentPage: 1,
      totalRecords: 0,
      users: [],
      processing: false,
      processingAll: false,
      errorProcessing: false,
      errorMessage: '',
      loading: false,
      errorLoading: false,
      routes: [],
      filter: {
        corporateId: null
      },
      notification: {
        title: '',
        notifyAll: false,
        isSms: false,
        isEmail: false,
        description: ''
      },
      search: '',
      selectedUsers: [],
      processingCorporateNotification: false,
      buttonsDisabled: false,
      fetchingData: false,
      isTitleEmpty: false
    }
  },
  created () {
    this.init()
  },
  computed: {

    showTable(){
      if(this.sortDirection === 'All') return true
      else if( this.sortDirection === 'Company' && this.filter.corporateId !== null) return true
      else return false
    },
    filteredUsers () {
      let users = this.users
      if (this.filter.corporateId) {
        users = this.users.filter(
          (user) => user.corporate_id === this.filter.corporateId
        )
      }
      const search = this.search.toLowerCase()
      return users.filter((user) => {
        return (
          user.fname.toLowerCase().includes(search) ||
          user.lname.toLowerCase().includes(search) ||
          user.email.toLowerCase().includes(search) ||
          user.phone.toLowerCase().includes(search)
        )
      })
    },
    disabledButton () {
      return !!(this.notification.description === '' ||
        this.notification.title === '' ||
        !this.selectedUsers.length)
    }
  },
  watch: {
    'notification.title' (value) {
      if (value) {
        this.isTitleEmpty = false
        return
      }

      this.isTitleEmpty = true
    },
    sortDirection (value) {
      if (value === 'All') {
        this.activeListTable = 'All'
        this.selectedUsers = []
      }
      if (value === 'Route') {
        this.activeListTable = 'Route'
        this.notification.notifyAll = false
           this.notificationType = 'regular'
        this.selectedUsers = []
      }

      if (value === 'Company') {
        this.activeListTable = 'Company'
        this.notification.notifyAll = false
        this.notificationType = 'regular'
        this.selectedUsers = []

      }
    },
    currentPage () {
      if (
        this.activeListTable === 'All' ||
        this.activeListTable === 'Company'
      ) {
        this.fetchUsers()
      }

      if (this.activeListTable === 'Route') {
        this.loadRoutes()
      }
    },
    search (value) {
      if (!value) {
        this.fetchUsers(true)
      }
    },
    'filter.corporateId' () {
      this.fetchUsers(true)
    },
  },

  methods: {
      companyName(data){
      return this.companies.find((c) => c.id === data)?.corporate_name
    },
    handleAllUsersSelection (e) {
      this.$nextTick(() => {
        if (e.target.checked) {
          this.notificationType = 'all'
          this.selectedUsers = this.users
        } else {
          this.notificationType = 'regular'
          this.selectedUsers = []
        }
      })
    },
    updatePostBody (body) {
      this.notification.description = body
    },
    focusInput () {
      this.$refs.titleRef.focus()
    },

    fetchCompanies () {
      // TODO: Review api update to do without hardcoding limit or alternative api
      return this.axios.get('/v1/corporates?limit=1000').then((res) => {
        const externalUsers = { id: -1, corporate_name: 'External Users' }
        this.companies = [externalUsers, ...res.data.data]
      })
    },
    async getCities () {
      if (this.cityFilters) {
        return
      }
      try {
        const cityData = await RouteResource.getCities()
        this.cityFilters = [
          { id: null, name: 'All Cities', city_id: null },
          ...cityData.data
        ]
      } catch (e) {

      }
    },
    init () {
      this.loading = true
      Promise.all([
        this.fetchUsers(),
        this.fetchCompanies(),
        this.loadRoutes()
      ]).finally(() => (this.loading = false))
    },
    fetchUsers (reset = false) {
      if (reset) {
        this.currentPage = 1
      }
      this.fetchingData = true
      const isSearch = !!this.search
      let url = `/v1/users${isSearch ? '/search' : ''}?limit=${
        this.filter.corporateId ? 10000 : this.perPage
      }&page=${this.currentPage}&metadata=true&sort[id]=desc&status=active`

      if (this.filter.corporateId && this.filter.corporateId > 0) {
        url += `&is_corporate=1&corporate_id=${this.filter.corporateId}`
      }

      if (this.filter.corporateId && this.filter.corporateId === -1) {
        url += '&is_corporate=1'
      }
      const request = isSearch
        ? this.axios.post(url, {
          user: this.search || ''
        })
        : this.axios.get(url)
      return request.then((res) => {
        if (this.notification.notifyAll) {
          res.data.data.map((i) => {
            this.selectUser(i)
          })
        }
        if (this.filter.corporateId) {
          this.notificationType = 'all'
          this.notification.notifyAll = true
          this.selectedUsers = res.data.data
        }
        this.fetchingData = false
        this.users = res.data.data
        this.totalRecords = res.data?.metadata?.total
        // if (this.totalUserCount === 0) {
        //   this.totalUserCount =  res.data?.metadata?.total
        // }
      })
    },

    async loadRoutes (reset = false) {
      try {
        if (reset) {
          this.currentPage = 1
        }
        const options = {
          params: {
            limit: this.perPage,
            page: this.currentPage,
            metadata: true,
            status: this.statusFilter,
            ...(this.routesFilter ? { search: this.routesFilter } : {}),
            ...(this.cityFilter?.city_id
              ? { city_id: this.cityFilter.city_id }
              : {}),
            corporate_id: this.filter.corporateId
              ? this.filter.corporateId
              : this.corporateId
                ? this.corporateId
                : ''
          }
        }

        this.$Progress.start()
        this.fetchingData = true
        const data = await RouteResource.listRoute(options)
        await this.getCities()
        const filteredArray = data?.data.filter((r) => {
          return r.corporate_id === this.corporateId
        })
        this.routes = !this.corporateId ? data?.data : filteredArray
        this.totalRecords = data?.metadata.total
        this.$Progress.finish()
        this.fetchingData = false
      } catch (e) {
        this.$Progress.fail()
        this.fetchingData = false
      }
    },

    async fetchCompaniesList () {
      const result = await this.axios.get('/v1/corporates')
      this.companiesList = result.data.data.map((c) => {
        return c.corporate_name
      })
    },
    removeSelectedUser(selectedUser)
    {
      const index = this.selectedUsers.findIndex(
        (user) => user.id === selectedUser.id
      )
      this.selectedUsers.splice(index, 1)
    },
    selectUser(selectedUser)
    {
      const index = this.selectedUsers.findIndex(
        (user) => user.id === selectedUser.id
      )
      if (index !== -1) {
        this.selectedUsers.splice(index, 1)
      } else {
        this.selectedUsers.push(selectedUser)
      }
    },
    async notifyUsers () {
      if (this.notification.description === '') {
        this.$toastr.w('Please enter notification message', 'Warning')
        return
      }

      if (this.notification.title === '') {
        this.isTitleEmpty = true
        this.$toastr.w('Please enter notification title', 'Warning')
        return
      }
      await this.$swal({
        icon: 'question',
        title: 'Please Confirm',
        text: `Are you sure you want to notify ${
          this.notificationType !== 'all' ? this.selectedUsers.length : 'all'
        } users?`, // here
        showConfirmButton: true,
        showCancelButton: true,
        preConfirm: () => {
          let payload = {
            body: `<html>${this.notification.description}</html>`,
            title: this.notification.title,
            sms: this.notification.isSms,
            email: this.notification.isEmail
          }
          if (this.notificationType === 'all' && !this.filter.corporateId) {
            payload = {
              ...payload,
              all: true
            }
            this.processingAll = true
          } else {
            payload = {
              ...payload,
              user_ids: this.selectedUsers.map((user) => user.id)
            }
            this.processing = true
          }
          return this.axios
            .post('/v1/notifications', {
              ...payload
            })
            .then(() => {
              this.filter = {
                corporateId: null
              }
              this.notification = {
                notifyAll: false,
                isSms: false,
                title: ''
              }
              this.selectedUsers = []
              this.notification.description = ''

              this.errorMessage = ''
              this.buttonsDisabled = true
            })
            .catch((e) => {
              let msg = e?.toString()
              if (
                e &&
                e.response &&
                e.response.data &&
                e.response.data.message
              ) {
                msg = e.response.data.message
              }
              this.errorMessage = msg
              this.$swal().showValidationMessage(msg)
            })
            .finally(() => {
              this.processing = false
              this.processingAll = false
              this.buttonsDisabled = false
            })
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal({
            icon: 'success',
            title: 'Notifications sent',
            text: `All ${this.notificationType !== 'all' ? 'selected' : ''} users were successfully notified`,
            showCloseButton: true
          })
        }
      })
    },
    prepareImageUpload (event) {
      const fileReader = new FileReader()
      fileReader.onload = (e) => {
        this.notification.splashScreenImage = e.target.result
      }
      fileReader.readAsDataURL(event.target.files[0])
    },
    openImageSelector () {
      this.$refs.imageInput.click()
    },
    resetUIState () {
      this.filter = {
        corporateId: null
      }
      this.notification = {
        notifyAll: false,
        isFlash: false,
        splashScreenImage: null,
        title: '',
        description: ''
      }
      this.selectedUsers = []
    },
    async notifyCorporateUsers () {
      if (
        this.notification.title === '' ||
        this.notification.description === ''
      ) {
        this.$toastr.e('Please provide the notification title and description')
        return
      }
      this.errorMessage = ''
      const result = await this.$swal({
        icon: 'question',
        title: 'Please Confirm',
        text: 'Are you sure you want to send this notification to all users?',
        showConfirmButton: true,
        showCancelButton: true
      })
      if (!result.isConfirmed) {
        return
      }
      const payload = {
        title: this.notification.title,
        body: `<html>${this.notification.description}</html>`,
        all: false,
        group_ids: [this.filter.corporateId],
        user_ids: null
      }
      this.processingCorporateNotification = true
      this.buttonsDisabled = true
      this.axios
        .post('/v1/notifications', payload)
        .then((res) => {
          this.resetUIState()
          this.$swal({
            icon: 'success',
            title: 'Notifications sent',
            text: 'All users in the corporate were successfully notified',
            showCloseButton: true
          })
        })
        .catch((e) => {
          const msg = extractErrorMessage(e, 'Failed to send notification')
          this.errorMessage = msg
        })
        .finally(() => {
          this.processingCorporateNotification = false
          this.buttonsDisabled = false
        })
    }
  },

  mounted () {
    this.focusInput()
  }
}
</script>
<style>
input[type=checkbox][disabled]{
  outline:1px solid black;
  background-color: black;
  cursor: not-allowed;
}
</style>
<style lang="scss" scoped>
@use 'src/assets/scss/partials/sh-colors';

.selected-users {
  height: 100%;
  .card-header {
    max-height: 3.75rem;
  }
  .text-grey {
    color: sh-colors.$sh-neutral-600;
  }

  .notificationArea {
    resize: none;
  }
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}
</style>
